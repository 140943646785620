import React from "react";

const Footer = () => {
    return (
        <div>

            <div class="footerWrapper container-fluid bgColorTan">
                <div>
                    <a class="copyrightLink" href="https://www.w3schools.com">© 2025 CodeNameKD</a>
                </div>
                <div>
                    <a href="https://www.linkedin.com/in/kayla-durham/" target="_blank" rel="noopener noreferrer" alt="KD LinkedIn Profile Link">
                        <img class="footerIconLink" src="/images/LinkenIn.png" alt="LinkedIn Profile"></img>
                    </a>
                </div>
            </div>

        </div>

    );
}
export default Footer;